import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="contextual-inquiry" mdxType="Subpage">
      <h3>{`Contextual Inquiry`}</h3>
      <p>{`Now we'll turn back to the Holtzblatt text to learn more about methods of contextual inquiry.`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Read Chapter 3 (Principles of Contextual Inquiry) from `}<a parentName="p" {...{
            "href": "https://learning.oreilly.com/library/view/contextual-design-2nd/9780128011362/"
          }}>{`Holtzblatt`}</a>{`.`}</p>
      </Callout>
    </Subpage>
    <Subpage slug="conceptual-models" mdxType="Subpage">
      <h3>{`Conceptual Models`}</h3>
      <p>{`Next lets continue our course from Udacity and learn about conceptual models.`}</p>
      <Callout lead={true} color="alternate" mdxType="Callout">
        <p>{`Complete lesson 2 from `}<a parentName="p" {...{
            "href": "https://www.udacity.com/course/design101"
          }}>{`Norman's Udacity course`}</a>{`.`}</p>
      </Callout>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      